import React from "react";

const experienceContent = [
  {
    year: " 2024 - Present ",
    position: " Engineering Manager",
    companyName: "Web3 Company",
    details: `Focusing on newest blockchain technology. As an Engineering Manager I manage engineer team and bridge between product needs and development team to reach our goals`,
  },
  {
    year: " 2022 - 2024 ",
    position: " Engineering Manager",
    companyName: "PT.Enkripsi Teknologi Handal (Nobi. usenobi.com)",
    details: `As an Engineering Manager I manage engineer team and bridge between product needs and development team to reach our goals`,
  },
  {
    year: " 2018 - 2022 ",
    position: " Tech Lead",
    companyName: "PT.Amandjaja Multifortuna Perkasa",
    details: `My career growing faster in my first company, as a Tech Lead I manage engineer team and bridge between product needs and tech stack`,
  },
  {
    year: " 2016 - 2018 ",
    position: " Programmer Lead",
    companyName: "PT.Amandjaja Multifortuna Perkasa",
    details: `The Company owner, give trust to me to lead some programmer team member`,
  },
  {
    year: " 2015 - 2016 ",
    position: " Fullstack Programmer",
    companyName: "PT.Amandjaja Multifortuna Perkasa",
    details: `It's my first company since I was graduated from college `,
  },
  {
    year: " 2014 - 2015 ",
    position: " CEO",
    companyName: "Crossdev Indonesia",
    details: `Crossdev Indonesia is Software House which was founded by six Diponegoro University College Students`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.companyName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
