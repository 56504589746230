import React from "react";

const educationContent = [
  {
    year: "2015",
    degree: "BACHELOR DEGREE",
    institute: "COMPUTER ENGINEERING, DIPONEGORO UNIVERSITY",
    details: `GPA: 3.7 (Cum Laude) - 3 Years 5 Months 29 Days - Fastest and Best in Faculty Graduation`,
  },
  {
    year: "2011",
    degree: "SENIOR HIGH SCHOOL ",
    institute: "SMA Negeri 1 Bobotsari",
    details: ``,
  },
  {
    year: "2008",
    degree: "JUNIOR HIGH SCHOOL ",
    institute: "SMP Negeri 6 Purwokerto",
    details: `OSIS as Seksi Pendahuluan Bela Negara`,
  },
  {
    year: "2005",
    degree: "ELEMENTARY SCHOOL ",
    institute: "SD Negeri 1 Arcawinangun, Purwokerto",
    details: ``,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-graduation-cap"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
