import React from "react";

const Address = () => {
  return (
    <>
      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-map position-absolute"></i>
        <span className="d-block">Address Point</span>Purbalingga,
          Central Java.
      </p>
      {/* End .custom-span-contact */}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-open position-absolute"></i>
        <span className="d-block">mail me</span>{" "}
        <a href="mailto:me@verzth.work">me@verzth.work</a>
      </p>
      {/* End .custom-span-contact */}

      {/*<p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-whatsapp position-absolute"></i>
        <span className="d-block">wa me</span>{" "}
        <a href="https://wa.me/+6282250000311?text=Hi" target={"_blank"}>+62 822 50.000 311</a>
      </p>*/}
      {/* End .custom-span-contact */}
    </>
  );
};

export default Address;
