import React from "react";

const personalInfoContent = [
  { meta: "first name", metaInfo: "Dodi" },
  { meta: "last name", metaInfo: "Triwibowo" },
  { meta: "Year of Birth", metaInfo: "1993" },
  { meta: "Nationality", metaInfo: "Indonesia" },
  { meta: "Address", metaInfo: "Purbalingga, Central Java" },
  { meta: "Email", metaInfo: "me@verzth.work" },
  { meta: "Languages", metaInfo: "Indonesia, English" },
  { meta: "Github", metaInfo: "<a href='https://github.com/verzth' target='_blank'>github.com/verzth</a>" },
  { meta: "Private Repo", metaInfo: "<a href='https://git.verzth.work' target='_blank'>git.verzth.work</a>" },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            <div dangerouslySetInnerHTML={{__html: val.metaInfo}} />
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
